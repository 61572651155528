import axios from "axios";
import swal from 'sweetalert2';
import i18n from '../config/i18n';
import history from "../config/history";

const baseUrl = 'https://platform.tastamat.com/platform/v1/rest/opener';

export function openTastamat(object) {
  return axios.put(`${baseUrl}/open`, JSON.stringify(object));
}

export function saveIin(object) {
  return axios.post(`${baseUrl}/save-iin`, JSON.stringify(object));
}

export function openIntercom(object) {
  return axios.put(`${baseUrl}/open-intercom`, JSON.stringify(object));
}

export function getTastamatInfo(code) {
  return axios.get(`${baseUrl}/info/${code}`)
}

export function getTopHints() {
  // return axios.get(`/a/dictionaries/opener.rate.hints.top/fields`)
  return [
    "Удобно", "Быстро", "Супер"
  ]
}
export function getOtherHints() {
  // return axios.get(`/a/dictionaries/opener.rate.hints.other/fields`)
  return [
    "Плохо", "Неудобно"
  ]
}

export function rateLocker(object) {
  return axios.put(`${baseUrl}/rate`, JSON.stringify(object))
    .then(res => swal(i18n.t('thanksForVote'), '', 'success').then(()=>window.location.reload()))
}

export function handleError(error, orderCode) {
  let err = {};
  try { err = JSON.parse(error.response.data.message)} catch (e) {}
  history.push({
    pathname: `/error/${orderCode}`,
    state: { error: err }
  })
}

import * as React from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import { StoreProvider } from "./config/store-context";
import Routes from './routes';
import history from "./config/history";
import i18n from './config/i18n';
import axiosInterceptorsConfig from "./config/axios-config";

import { Cookies } from "react-cookie";

const cookies = new Cookies();

axiosInterceptorsConfig();

export default () => {
  var lng = cookies.get("i18lang");

  // console.log(lng);

  i18n.changeLanguage(
    lng ? lng : "ru"
  );
  return(
    <StoreProvider>
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
            <Routes />
        </Router>
      </I18nextProvider>
    </StoreProvider>
  )
};
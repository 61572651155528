import React, { useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../components/loading-spinner';
import { getInfo } from "../services/lockerdrop.service";
import { useStore } from "../config/store-context";

const QrRoute = Loadable({
  loader: () => import('./qr/qr.route'),
  loading: Loading,
});

const AboutRoute = Loadable({
  loader: () => import('./about/about.route'),
  loading: Loading,
});

const InputRoute = Loadable({
  loader: () => import('./input/input.route'),
  loading: Loading,
});

const RateRoute = Loadable({
  loader: () => import('./rate/rate.route'),
  loading: Loading,
});

const ErrorRoute = Loadable({
  loader: () => import('./error/error.route'),
  loading: Loading,
});

const PaymentRoute = Loadable({
  loader: () => import('./payment/payment.route'),
  loading: Loading,
});

const FAQRoute = Loadable({
  loader: () => import('./FAQ/faq.route'),
  loading: Loading,
});

const AppealRoute = Loadable({
  loader: () => import('./appeal/appeal.route'),
  loading: Loading,
});

const LockerRoute = Loadable({
  loader: () => import('./locker/locker.route'),
  loading: Loading,
});


////// Drop ////////

const QrDropRoute = Loadable({
  loader: () => import('./qrdrop/qr.route'),
  loading: Loading,
});

const AboutDropRoute = Loadable({
  loader: () => import('./aboutdrop/about.route'),
  loading: Loading,
});

const InputDropRoute = Loadable({
  loader: () => import('./inputdrop/input.route'),
  loading: Loading,
});

const ErrorDropRoute = Loadable({
  loader: () => import('./errordrop/error.route'),
  loading: Loading,
});

const IssuedInfoDropRoute = Loadable({
  loader: () => import('./issued-info/issued-info.route'),
  loading: Loading,
});

const LockerDropRoute = Loadable({
  loader: () => import('./lockerdrop/locker.route'),
  loading: Loading,
});

const NotFound = () => <h2>NotFound</h2>;

// class Routes extends React.Component {
  
//   render() {
//     return (
//       <Switch>
//         <Route path="/o/:orderCode" component={AboutRoute} exact/>
//         <Route path="/q/:orderCode" component={QrRoute}  />
//         <Route path="/i/:orderCode" component={InputRoute} />
//         <Route path="/r/:orderCode" component={RateRoute} />
//         <Route path="/e/:orderCode" component={ErrorRoute} />
//         <Route path="/p/:orderCode" component={PaymentRoute} />
//         <Route path="/l/:orderCode" component={LockerRoute} />
//         <Route path="/faq" component={FAQRoute} />
//         <Route path="/appeal" component={AppealRoute} />

//         <Route path="/d/:orderCode" component={AboutDropRoute} exact/>
//         <Route path="/qd/:orderCode" component={QrDropRoute}  />
//         <Route path="/id/:orderCode" component={InputDropRoute} />
//         <Route path="/is/:orderCode" component={IssuedInfoDropRoute} />
//         <Route path="/ld/:orderCode" component={LockerDropRoute} />
//         <Route path="/ed/:orderCode" component={ErrorDropRoute} />

//         <Route path="*" component={NotFound} />
//       </Switch>
//     );
//   }
// }

// export default Routes;

export const Routes = () => {
  const { dispatch, state: { orderCode } } = useStore()

  useEffect(() => {
    if (orderCode) {
      getInfo(orderCode, dispatch)
    }
  }, [orderCode, dispatch])

  const routes = [
    { path: '/d/:orderCode', component: AboutDropRoute },
    { path: '/qd/:orderCode', component: QrDropRoute },
    { path: '/id/:orderCode', component: InputDropRoute },
    { path: '/is/:orderCode', component: IssuedInfoDropRoute },
    { path: '/ld/:orderCode', component: LockerDropRoute },
    { path: '/ed/:orderCode', component: ErrorDropRoute },
    { path: '*', component: NotFound }
  ]

  return (
    <Switch>
      <Route path="/o/:orderCode" component={AboutRoute} exact/>
      <Route path="/q/:orderCode" component={QrRoute}  />
      <Route path="/i/:orderCode" component={InputRoute} />
      <Route path="/r/:orderCode" component={RateRoute} />
      <Route path="/e/:orderCode" component={ErrorRoute} />
      <Route path="/p/:orderCode" component={PaymentRoute} />
      <Route path="/l/:orderCode" component={LockerRoute} />
      <Route path="/faq" component={FAQRoute} />
      <Route path="/appeal" component={AppealRoute} />
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  )
}

export default Routes;